/* client/src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom global styles */
body {
  font-family: 'Inter', sans-serif;
}
html,
body,
#root {
  height: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
}

a {
  transition: color 0.3s;
}

button {
  transition: background-color 0.3s, transform 0.2s;
}

button:hover {
  transform: translateY(-2px);
}

input,
textarea {
  border-radius: 0.375rem;
  border: 1px solid #ccc;
  padding: 0.5rem;
  width: 100%;
}

/* Animations */
@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fade-in 1s ease-out forwards;
}

.animate-fade-in-delay {
  animation: fade-in 1s ease-out forwards;
  animation-delay: 0.5s;
}

.animate-fade-in-delay-2 {
  animation: fade-in 1s ease-out forwards;
  animation-delay: 1s;
}
