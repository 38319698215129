/* src/pages/ModelDetailsPage.css */

/* Override the thumbnail image height */
.thumb{
    width : 100px !important;
}
.custom-carousel .carousel .thumbs-wrapper .thumb img {
    height: 60px; /* Adjust this value as needed */
    width: auto;  /* Maintain aspect ratio */
    object-fit: cover; /* Ensure the image covers the thumbnail area */
  }
  
  /* Optional: Adjust the margin between thumbnails */
  .custom-carousel .carousel .thumbs-wrapper .thumb {
    margin: 0 5px; /* Adjust spacing between thumbnails */
  }
  
  
  